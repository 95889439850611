.page-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: #ffffff;
}

.shuffle-button-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10; /* High z-index to stay on top */
}

.shuffle-button {
  padding: 15px 30px;
  font-size: 22px;
  border-radius: 50%;
  background-color: transparent;
  border: 3px solid red;
  color: red;
  cursor: pointer;
  position: relative;
  z-index: 10; /* High z-index to stay on top */
}

@keyframes rotateShuffle {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.thumbnail-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.thumbnail-item {
  width: 200px;
  height: 200px;
  background-size: cover;
  background-position: center;
  position: absolute;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.5s ease, filter 0.5s ease, opacity 0.5s ease-in-out;
  cursor: pointer;
}

.thumbnail-item:hover {
  transform: scale(1.1);
  z-index: 6; /* Hovered thumbnail stays behind the Shuffle button */
}

.blurred {
  filter: blur(5px);
  opacity: 0.5;
}

/* Larger hover title style with line breaks */
.hovered-title-large {
  font-family: 'CustomFont', sans-serif;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,/* Continued from previous CSS */
  -50%) rotate(15deg); /* Increased tilt for a more dramatic effect */
  font-size: 10rem; /* Larger size */
  color: red;
  opacity: 0;
  white-space: normal; /* Allow line breaks */
  text-align: center;
  word-wrap: break-word; /* Ensure words break to the next line if too long */
  pointer-events: none;
  z-index: 15;
}

.hovered-title-large.show {
  opacity: 1;
  transform: translate(-50%, -50%) rotate(15deg) scale(1.1);
  transition: transform 0.5s ease, opacity 0.5s ease;
}
