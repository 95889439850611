.upload-container {
  transform: perspective(1000px) rotateX(15deg);
  transition: transform 0.5s ease;
}

.upload-container:hover,
.upload-form input:focus,
.upload-form textarea:focus {
  transform: perspective(1000px) rotateX(0deg);
}

.upload-form {
  font-family: "Arial Black", sans-serif;
  font-style: italic;
  background: rgba(255, 255, 255, 0.1);
  padding: 30px;
  border-radius: 10px;
  width: 100%;
  max-width: 800px;
  margin: auto;
  transition: transform 0.3s ease;
  color: red;
  font-size: 1.5rem; /* Large text */
}

.upload-form input,
.upload-form textarea {
  background: transparent;
  font-size: 1.5rem;
  width: 100%;
  margin-bottom: 1.5rem; /* More spacing */
  padding: 0.75rem; /* Larger input fields */
  border: 2px solid #ccc;
  border-radius: 5px;
  color: red; /* Red font color */
}

.upload-form label {
  font-size: 1.5rem; /* Larger labels */
  margin-bottom: 10px;
  display: block;
}

.upload-form button,
.upload-form input[type="file"] {
  font-size: 1.8rem; /* Larger button text */
  padding: 0.75rem 2.5rem;
  background-color: #ff0303;
  color: white;
  border: none;
  cursor: pointer;
  transition: transform 0.3s ease;
  width: auto;
}

.upload-form button[type="button"],
.upload-form input[type="file"] {
  display: inline-block;
  margin-right: 10px; /* Add some spacing between buttons */
}

.upload-form button:hover,
.upload-form input[type="file"]:hover {
  transform: scale(1.1);
  background-color: #848484; /* Change background on hover for buttons */
}

.success-message {
  font-size: 2.5rem;
  color: green;
  text-align: center;
}

.error-message {
  color: red;
  font-size: 1.5rem;
}

.category-checkboxes {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.category-checkboxes div {
  display: inline-block;
  padding: 10px 20px;
  border: 2px solid red;
  border-radius: 50px; /* Make it oval */
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  font-size: 1.3rem;
  background-color: white; /* Initial background is white */
  color: red; /* Text color is red by default */
}

.category-checkboxes div:hover {
  background-color: rgba(255, 0, 0, 0.2); /* Hover effect */
}

.category-checkboxes div.selected {
  background-color: red;
  color: white;
}

/* Keep selected state after click */
.category-checkboxes div.active {
  background-color: red;
  color: white;
}

/* Hide the actual checkboxes */
.category-checkboxes input {
  display: none;
}
