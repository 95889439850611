body {
  overflow: hidden;
}

.elegant-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1; /* Behind the main content */
  background-color: black; /* Solid black background */
}

.text-container {
  height: 100vh;
  width: 100vw;
  overflow-y: scroll;
  perspective: 1500px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.scroll-text {
  font-size: 3rem;
  line-height: 1.5;
  transform-style: preserve-3d;
  transform-origin: center;
  text-align: center;
  width: 80%;
  transition: transform 0.2s ease-out;
  color: white; /* Ensure the text is white and clearly visible */
  text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.5); /* Glowing white text */
}

.scroll-text strong {
  color: red; /* Strong red highlights for emphasis */
  text-shadow: 0px 0px 15px rgba(255, 0, 0, 0.7); /* Glowing red for important text */
}

p {
  margin-bottom: 3rem;
  letter-spacing: 0.1rem; /* Adds elegance to the spacing */
}

.scroll-text:hover {
  text-shadow: 0px 0px 15px rgba(255, 255, 255, 0.8); /* Intensify glow on hover */
}
